import React from "react";

const Home = () => {
  return (
    <div>
      PeppySage Home page comming soon...
      <br></br>
      <br></br>
      Work in progress...
    </div>
  );
};

export default Home;
