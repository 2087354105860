import React from "react";
import "./App.css";

//3rd Party Imports
import { BrowserRouter as Router } from "react-router-dom";

//App Imports
import Routes from "./routes";

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes />
    </Router>
  );
}

export default App;
