import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import { Home } from "../pages";

const Routes = () => {
  console.log("====> Rendering Routes");

  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} />
      </Switch>
    </>
  );
};

export default Routes;
